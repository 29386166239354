<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        v-for="niveau in niveaux"
        :key="niveau.id"
        cols="12"
      >
        <v-card
          :id="niveau.id"
          inline
          :title="niveau.nom"
          class="px-5 py-3"
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="8"
              >
                <i18n-text-field
                  :value.sync="niveau.nom"
                  label="Nom"
                  data-cy="niveau-option-nom"
                />
              </v-col>
              <v-col
                cols="4"
              >
                <v-text-field
                  v-model="niveau.niveau"
                  label="Niveau"
                  data-cy="niveau-option-niveau"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="8"
              >
                <v-select
                  v-model="niveau.scenarioDepart"
                  :items="listeIdentifiantsScenarios"
                  item-text="identifiant"
                  item-value="identifiant"
                  data-cy="lb-scenarioDepart"
                />
              </v-col>
              <v-col
                cols="4"
              >
                <v-text-field
                  v-model="niveau.minutesReconnues"
                  label="Minutes reconnues"
                  data-cy="niveau-option-minutes"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="8"
              >
                <v-btn
                  class="primary"
                  data-cy="niveau-option-sauvegarder"
                  @click="onSauvegarderNiveau(niveau)"
                >
                  <v-icon
                    dark
                    medium
                    left
                  >
                    mdi-check-circle
                  </v-icon>
                  Sauvegarder
                </v-btn>
              </v-col>
              <v-col
                cols="4"
              >
                <v-btn
                  data-cy="niveau-option-effacer"
                  @click="onEffacerNiveau(niveau)"
                >
                  <v-icon
                    dark
                    medium
                    left
                  >
                    mdi-trash-can-outline
                  </v-icon>
                  Effacer
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
      class="primary"
      @click="onCreerNiveau()"
    >
      <v-icon
        dark
        medium
        left
      >
        mdi-plus-box
      </v-icon>
      Ajouter un niveau
    </v-btn>
  </v-container>
</template>
<script>
  import restApiService from '@/services/restApiService.js'
  import I18nTextField from '../composants/I18nTextField.vue'

  export default {
    components: {
      I18nTextField,
    },
    data: () => ({
      niveaux: [],
      listeIdentifiantsScenarios: [],
    }),
    mounted () {
      restApiService.get('/api/scenarios').then((result) => {
        this.listeIdentifiantsScenarios = result.data
      })
      this.charger()
    },
    methods: {
      charger () {
        restApiService.get('/api/niveaus').then((result) => {
          this.niveaux = result.data
          this.niveaux.sort(function (n1, n2) {
            return n1.niveau - n2.niveau
          })
        })
      },
      onCreerNiveau () {
        this.$dialog
          .prompt({
            title: "Création d'un nouveau niveau",
            body: 'Quel est le nom que vous souhaitez attribuer au niveau',
          }, {
            promptHelp: "Type in the box below and click '[+:okText]'",
          })
          .then((r) => {
            const infoNiveau = {
              nom: r.data,
              scenarioDepart: '',
              niveau: 0,
            }
            restApiService.post('/api/niveaus', infoNiveau).then(() => {
              this.charger()
            })
          },
          )
      },
      onSauvegarderNiveau (niveau) {
        restApiService.put('/api/niveaus', niveau).then(() => {

        })
      },
      onEffacerNiveau (niveau) {
        restApiService.delete('/api/niveaus/' + niveau.id).then(() => {
          this.charger()
        })
      },
    },
  }
</script>
