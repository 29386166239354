<template>
  <div>
    <v-text-field
      v-model="texteLangue"
      :label="libeleLangue"
      prepend-icon="mdi-translate"
      @click:prepend="changerLangue()"
      @input="miseAJour()"
    />
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      texte: '',
      fr: '',
      en: '',
      selectionLangue: 'fr',
      texteLangue: '',
    }),
    computed: {
      libeleLangue: function () {
        return this.label + ' ' + this.selectionLangue
      },
    },
    watch: {
      value: {
        handler (newValue) {
          this.onChangerTexte(newValue)
        },
      },
    },
    created () {
      this.texte = this.value
      if (this.texte.startsWith('<t>')) {
        const parser = new DOMParser()
        const dom = parser.parseFromString(this.texte, 'application/xml')
        this.fr = dom.documentElement.getElementsByTagName('fr')[0].textContent
        this.en = dom.documentElement.getElementsByTagName('en')[0].textContent
        this.texteLangue = this.fr
      } else {
        this.fr = this.texte
        this.en = this.texte
        this.texteLangue = this.texte
      }
    },
    methods: {
      miseAJour () {
        if (this.selectionLangue === 'fr') {
          this.fr = this.texteLangue
        } else { this.en = this.texteLangue }
        const parser = new DOMParser()
        const dom = parser.parseFromString('<t><fr></fr><en></en></t>', 'application/xml')
        dom.documentElement.getElementsByTagName('fr')[0].textContent = this.fr
        dom.documentElement.getElementsByTagName('en')[0].textContent = this.en
        this.$emit('update:value', dom.documentElement.outerHTML)
      },
      changerLangue () {
        if (this.selectionLangue === 'fr') {
          this.selectionLangue = 'en'
          this.texteLangue = this.en
        } else {
          this.selectionLangue = 'fr'
          this.texteLangue = this.fr
        }
      },
      onChangerTexte (newValue) {
        if (newValue.startsWith('<t>')) {
          const parser = new DOMParser()
          const dom = parser.parseFromString(newValue, 'application/xml')
          this.fr = dom.documentElement.getElementsByTagName('fr')[0].textContent
          this.en = dom.documentElement.getElementsByTagName('en')[0].textContent
          if (this.selectionLangue === 'fr') {
            this.texteLangue = this.fr
          } else {
            this.texteLangue = this.en
          }
        } else {
          this.fr = newValue
          this.en = newValue
          this.texteLangue = newValue
        }
      },
    },
  }

</script>
